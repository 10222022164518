<template>
  <div>
    <div class="container-fluid banner">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="banner-leilao col-lg-3" v-if="getLeilao">
            <div class="auction-item-2">
              <div class="auction-thumb border-radius-5 thumb-height-247">
                <img :src="getLeilao.foto.url" :title="getLeilao.nome" />
              </div>
              <div class="field-badge">
                <span class="badge badge-gray bg-secondary w-100" v-if="getLeilao.tipo == 'JUDICIAL'">JUDICIAL</span>
                <span class="badge badge-yellow bg-secondary w-100" v-else-if="getLeilao.tipo == 'EXTRAJUDICIAL'">EXTRAJUDICIAL</span>
                <span class="badge badge-orange bg-secondary w-100" v-else-if="getLeilao.tipo == 'VENDA DIRETA'">VENDA DIRETA</span>
                <span class="badge badge-yellow bg-secondary w-100" v-else>{{ getLeilao.tipo.toUpperCase() }}</span>
              </div>
              <div class="auction-content">
                <div class="title-adress text-center">
                  <h6 class="title mb-1 px-2 w-100 text-truncate">
                    {{ getLeilao.nome }}
                  </h6>
                  <p class="titulo-leilao">{{ getLeilao.titulo }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="banner-info col-lg-6" v-if="getLeilao">
            <div class="info-banner">
              <h3 class="pt-4 pb-2">{{ getLeilao.nome }}</h3>
              <div v-if="getLeilao.judicial">
                <p
                  class="titulo-descricao my-1"
                  v-for="praca in getLeilao.pracas"
                  :key="praca.pracaLeilaoId"
                  :class="{ 'leilao-fechado': praca.status == 2 }"
                >
                  <strong>{{ praca.numeroPraca }}ª LEILÃO </strong>
                  {{ praca.dataExecucao | formatDataHora }}
                </p>
              </div>
              <p class="titulo-descricao my-1" v-else>
                <strong>DATA E HORA: </strong>
                {{ getLeilao.dataLeilao | formatDataHora }}
              </p>
              <p class="titulo-descricao my-1">
                <strong>STATUS: </strong>
                <span
                  v-if="
                    getLeilao.statusSite === 'aberto' &&
                    (getLeilao.status === 'Aberto' || getLeilao.status === 'Agendado') &&
                    getLeilao.tipo != 'VENDA DIRETA'
                  "
                  >ABERTO PARA LANCES</span
                >
                <span
                  v-else-if="
                    getLeilao.statusSite === 'aberto' &&
                    (getLeilao.status === 'Aberto' || getLeilao.status === 'Agendado') &&
                    getLeilao.tipo == 'VENDA DIRETA'
                  "
                  >ABERTO</span
                >
                <span v-else-if="getLeilao.statusSite === 'aberto' && (getLeilao.status === 'Em Andamento' || getLeilao.status === 'Em Pregão')"
                  >EM PREGÃO<span class="ball-pregao box-pulse-yellow"></span
                ></span>
                <span v-else-if="getLeilao.statusSite === 'fechado' && getLeilao.status === 'Cancelado'">CANCELADO</span>
                <span v-else-if="getLeilao.statusSite === 'fechado' || getLeilao.status === 'Encerrado'">ENCERRADO</span>
                <span v-else>EM BREVE</span>
              </p>
              <p class="titulo-descricao my-1" v-if="getLeilao.nomeLeiloeiro">
                <strong>LEILOEIRO OFICIAL: </strong>
                {{ getLeilao.nomeLeiloeiro }}
              </p>
              <p class="titulo-descricao my-1" v-if="!getLeilao.judicial"><strong>COMITENTE: </strong> {{ getLeilao.nomeComitente }}</p>
            </div>
            <div class="buttons mt-3" v-if="getLeilao.anexos.length > 0">
              <ul class="lista-botoes">
                <li v-for="anexo in getLeilao.anexos" :key="anexo.ArquivoId">
                  <a class="edital-button" :href="anexo.arquivo.url" target="_blank">{{ anexo.nome }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="col-lg-3 text-center d-flex align-items-center justify-content-center"
            v-if="getLeilao.regrasHabilitacao && getLeilao.regrasHabilitacao.length > 0 && getLeilao.statusSite === 'aberto'"
          >
            <a
              v-if="
                (!getAuth || (getAuth && !getLeilaoHabilitado.habilitado && getLeilaoHabilitado.status != 'Pendente')) &&
                !getLeilao.habilitacaoDesativada
              "
              href="javascript:void(0)"
              class="custom-button leilao-button"
              @click="$emit('abreModalHabilitar')"
              data-target="#modalCondicoes"
              data-toggle="modal"
            >
              HABILITE-SE
            </a>
            <a
              v-if="
                (!getAuth || (getAuth && !getLeilaoHabilitado.habilitado && getLeilaoHabilitado.status != 'Pendente')) &&
                getLeilao.habilitacaoDesativada
              "
              href="javascript:void(0)"
              class="custom-button leilao-button"
              data-target="#modalHabilitacaoEncerrada"
              data-toggle="modal"
            >
              HABILITE-SE
            </a>
            <a v-if="getAuth && getLeilaoHabilitado.status == 'Pendente'" class="custom-button btn-yellow text-white"> HABILITAÇÃO EM ANÁLISE </a>
            <a
              v-if="getAuth && getLeilaoHabilitado.habilitado && getLeilao.tipo != 'VENDA DIRETA'"
              class="custom-button leilao-button text-white"
              @click="openPainel"
            >
              AUDITÓRIO VIRTUAL
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid descricao" v-if="getLeilao.observacao">
      <div class="container">
        <div class="row pt-3">
          <div class="col-md-12">
            <div class="infos d-flex">
              <p class="pb-1 col-12" v-html="getLeilao.observacao"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { PAINEL_URL, ORIGIN } from '@/config/config';
moment.locale('pt-br');
export default {
  data() {
    return {
      colorPrimary: process.env.VUE_APP_PRIMARY_COLOR,
      colorSecondary: process.env.VUE_APP_SECONDARY_COLOR,
      colorConstrast: process.env.VUE_APP_CONSTRAST_COLOR,
    };
  },
  computed: {
    ...mapGetters(['getLeilao', 'getLeilaoHabilitado', 'getAuth']),
  },
  methods: {
    openPainel() {
      const painel = window.open(PAINEL_URL, 'popup');
      let interval = null;

      window.addEventListener('message', (event) => {
        if (event.origin !== ORIGIN) return;
        clearInterval(interval);
      });

      setTimeout(() => {
        interval = setInterval(() => {
          painel.postMessage({ auth: this.getAuth, leilaoId: this.getLeilao.id }, PAINEL_URL);
          //painel.focus();
        }, 300);
      }, 300);
    },
  },
  filters: {
    formatDataHora(value) {
      return moment.utc(value).local().format('DD/MM/YYYY [às] HH:mm');
    },
  },
};
</script>

<style scoped>
.ball-pregao {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #f7d663;
  display: inline-block;
  margin-left: 8px;
}

.box-pulse-yellow {
  border: 1px solid #f9c718;
  box-shadow: 0 0 0 0 rgba(249, 199, 24, 1);
  animation: pulse-yellow 1.5s infinite;
}

@keyframes pulse-yellow {
  0% {
    box-shadow: 0 0 0 0 rgba(249, 199, 24, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(249, 199, 24, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(249, 199, 24, 0);
  }
}

.leilao-fechado {
  text-decoration: line-through;
}

.titulo-leilao {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0px 10px;
  font-size: 12px;
  margin: 0;
}
.custom-button {
  font-size: 15px;
}
.banner {
  background-color: v-bind(colorPrimary);
  margin-top: 88px;
  padding: 40px 0;
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 45%);
}

.descricao {
  padding-top: 15px;
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 5%);
}

.banner-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  color: #fff;
}

.banner-info h3,
.banner-info h6 {
  color: #fff;
}

.edital-button {
  padding: 10px;
  border-radius: 30px;
  background-color: v-bind(colorSecondary) !important;
  color: #fff !important;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.leilao-button {
  background-color: #fff !important;
  color: v-bind(colorPrimary) !important;
}

.titulo-descricao {
  font-weight: 400;
  font-size: 0.8rem;
}
.title {
  color: #fff;
}

.title-adress {
  display: flex;
  align-items: center;
  text-align: center;
  height: 120px;
  background-color: v-bind(colorSecondary);
  border-radius: 0px 0px 20px 20px;
  justify-content: center;
  flex-direction: column;
  padding-top: 10px;
}
.title-adress p {
  color: #fff;
}
.field-badge {
  width: 100%;
  position: absolute;
  top: 188px;
}

.fieldBadgeisAPageLeiloes {
  width: 270px !important;
  position: absolute;
}

.badge {
  display: flex;
  border-radius: 15px;
  height: 35px;
  font-size: 100%;
  justify-content: center;
  align-items: center;
  background-color: #d9d9d9 !important;
}

.icone-lance {
  width: 40px;
  height: 40px;
  color: #ffffff;
  line-height: 40px;
  font-size: 18px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.13);
}

.icone-lance-aberto {
  background-color: #2baf49;
}

.text-lance {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
  font-weight: 600;
}

.information {
  height: 13vw;
  margin: 0 auto 0px;
  border: 1px solid rgb(218, 218, 218);
  border-top: 0px;
  padding-top: 25px;
  padding-bottom: 25px;
  max-width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.detail-button {
  margin: 0 auto 30px;
  max-width: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auction-item-2 .auction-thumb {
  height: 200px;
  max-height: 200px;
  padding: 0;
  margin: 0;
  background-color: #fff;
  position: relative;
}

.auction-thumb a {
  width: 100%;
  height: 100%;
}

.auction-thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

.text-lance-aberto {
  color: #2baf49;
}

.text {
  font-size: 14px;
}

.text ul li {
  padding: 0;
}

.detail {
  width: 100% !important;
  text-align: center;
  background-color: #0b184a;
  color: #fff;
}

.buttons {
  text-align: center;
}

.badge-gray {
  background-color: #d9d9d9 !important;
}
.badge-yellow {
  background-color: #fdff83 !important;
}

.badge-orange {
  background-color: #f1d8a9 !important;
}

.btn-yellow {
  background-color: #e5c008 !important;
}

.lista-botoes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}

@media (max-width: 576px) {
  .banner {
    margin-top: 132px;
  }
}
</style>
