var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-leilao",class:_vm.classeRaiz},[_c('div',{staticClass:"auction-item-2"},[_c('div',{staticClass:"auction-thumb border-radius-5 thumb-height-247"},[_c('router-link',{attrs:{"to":'/lotes/' + _vm.leilao.id}},[_c('img',{attrs:{"src":_vm.leilao.foto.url,"title":_vm.leilao.nome}})])],1),_c('div',{staticClass:"field-badge"},[(_vm.leilao.tipo == 'JUDICIAL')?_c('span',{staticClass:"badge badge-gray bg-secondary w-100"},[_vm._v("JUDICIAL")]):(_vm.leilao.tipo == 'EXTRAJUDICIAL')?_c('span',{staticClass:"badge badge-yellow bg-secondary w-100"},[_vm._v("EXTRAJUDICIAL")]):(_vm.leilao.tipo == 'VENDA DIRETA')?_c('span',{staticClass:"badge badge-orange bg-secondary w-100"},[_vm._v("VENDA DIRETA")]):_c('span',{staticClass:"badge badge-yellow bg-secondary w-100"},[_vm._v(_vm._s(_vm.leilao.tipo.toUpperCase()))])]),_c('div',{staticClass:"auction-content"},[_c('div',{staticClass:"title-adress text-center"},[_c('h6',{staticClass:"title mb-1 px-2 w-100 text-truncate"},[_vm._v(" "+_vm._s(_vm.leilao.nome)+" ")]),_c('p',{staticClass:"titulo-leilao"},[_vm._v(_vm._s(_vm.leilao.titulo))])])])]),_c('div',{staticClass:"information"},[(_vm.leilao.pracas.length > 0)?_c('div',{staticClass:"text"},[_c('ul',_vm._l((_vm.leilao.pracas),function(praca){return _c('li',{key:praca.pracaLeilaoId,staticClass:"pb-2",class:{
            'leilao-aberto': _vm.isPracaAtual(praca),
            'leilao-fechado': praca.status == 2,
          }},[_c('strong',[_vm._v(_vm._s(praca.numeroPraca)+"ª LEILÃO: ")]),_vm._v(_vm._s(_vm._f("formatDataHora")(praca.dataExecucao))+" "),_c('br'),(_vm.leilao.qtdLotes == 1)?_c('span',[_c('strong',[_vm._v("LANCE INICIAL: ")]),_vm._v(_vm._s(_vm._f("currency")(praca.lanceInicial,'R$', 2, { spaceBetweenAmountAndSymbol: true, decimalSeparator: ',', thousandsSeparator: '.', })))]):_vm._e()])}),0)]):_c('div',[_c('ul',[_c('li',[_c('strong',[_vm._v("DATA: ")]),_vm._v(_vm._s(_vm._f("formatDataHora")(_vm.leilao.dataLeilao)))]),_c('li',[_c('strong',[_vm._v("CATEGORIA: ")]),_vm._v(_vm._s(_vm.leilao.categoria))]),_c('li',[_c('strong',[_vm._v("Nº LOTES: ")]),_vm._v(_vm._s(_vm.leilao.qtdLotes))])])])]),_c('div',{staticClass:"detail-button"},[_c('router-link',{staticClass:"custom-button detail",class:{
        'box-pulse-yellow': _vm.leilao.statusSite === 'aberto' && (_vm.leilao.status === 'Em Andamento' || _vm.leilao.status === 'Em Pregão'),
        'status-cancelado': _vm.leilao.status === 'Cancelado',
        'status-encerrado': _vm.leilao.status === 'Encerrado',
      },attrs:{"to":'/lotes/' + _vm.leilao.id}},[(_vm.leilao.statusSite === 'aberto' && (_vm.leilao.status === 'Aberto' || _vm.leilao.status === 'Agendado'))?_c('span',[_vm._v("ABERTO")]):(_vm.leilao.statusSite === 'aberto' && (_vm.leilao.status === 'Em Andamento' || _vm.leilao.status === 'Em Pregão'))?_c('span',[_vm._v("EM PREGÃO")]):(_vm.leilao.statusSite === 'fechado' && _vm.leilao.status === 'Cancelado')?_c('span',[_vm._v("CANCELADO")]):(_vm.leilao.statusSite === 'fechado' || _vm.leilao.status === 'Encerrado')?_c('span',[_vm._v("ENCERRADO")]):_c('span',[_vm._v("EM BREVE")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }